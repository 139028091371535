import request from '@/utils/request'

// export const myCommission = (params) => {
//   return request({
//     url: 'member/commissionDetails/getCommissionIndex',
//     method: 'GET',
//     params,
//   })
// }
//根据登陆人获取门店
export const storeName = () => {
  return request({
    url: 'sys/sysStoreUser/queryStores',
    method: 'GET',
  })
}
//我的提成明细
export const commissionDel = (params) => {
  return request({
    url: 'member/commissionDetails/queryStoreByPage',
    method: 'GET',
    params,
  })
}
//获取服务中的列表数据
export const serviceData = (params) => {
  return request({
    url: 'member/commissionDetails/queryOngoingByPage',
    method: 'GET',
    params,
  })
}

//根据门店获取店长
export const getStoreUser = () => {
  return request({
    url: 'sys/sysStoreUser/queryUserByStore',
    method: 'GET',
  })
}
