<template>
  <div class="myCommission content-index">
    <el-page-header @back="goBack" content="我的提成明细"></el-page-header>
    <div class="formBox">
      <el-form
        :model="formData"
        ref="ruleForm"
        class="demo-ruleForm"
        label-width="10px"
      >
        <el-form-item prop="region">
          <el-date-picker
            v-model="dates"
            type="daterange"
            align="right"
            unlink-panels
            size="medium"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="selectTimes"
            value-format="yyyy-MM-dd"
            style="width: 300px;"
          ></el-date-picker>
        </el-form-item>
        <el-form-item prop="type">
          <el-select
            v-model="formData.type"
            placeholder="全部类型"
            size="medium"
            clearable
          >
            <el-option
              :label="item.text"
              :value="item.value"
              v-for="item in typeList"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="clothesNum">
          <el-input
            v-model="formData.clothesNum"
            size="medium"
            placeholder="请输入手机号/衣物号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="status">
          <el-select
            v-model="formData.status"
            placeholder="全部状态"
            size="medium"
            clearable
          >
            <el-option
              :label="item.text"
              :value="item.value"
              v-for="item in statusList"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="storeId">
          <el-select
            v-model="formData.storeId"
            placeholder="全部门店"
            size="medium"
            clearable
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in storeList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="medium" @click="queryInfo">
            查询
          </el-button>
          <el-button size="medium" @click="reSet">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-row class="completeOrservice" v-loading="loading">
      <el-col :span="12">
        <div>
          <h3>已完成</h3>
          <el-row class="completeCtn" :gutter="20">
            <el-col :span="12">
              <el-card class="totalCommission">
                <p>实际总提成</p>
                <p>
                  {{
                    pageData.totalCommission ? pageData.totalCommission : 0
                  }}元
                </p>
              </el-card>
            </el-col>
            <el-col :span="12">
              <el-card>
                <div class="commissionItem">
                  <span>取衣提成</span>
                  <span>
                    {{
                      pageData.tackCommission ? pageData.tackCommission : 0
                    }}元
                  </span>
                </div>
              </el-card>
              <el-card class="topDistance">
                <div class="commissionItem">
                  <span>办卡提成</span>
                  <span>
                    {{
                      pageData.cardCommission ? pageData.cardCommission : 0
                    }}元
                  </span>
                </div>
              </el-card>
              <el-card class="topDistance">
                <div class="commissionItem">
                  <span>充值提成</span>
                  <span>
                    {{
                      pageData.rechargeCommission
                        ? pageData.rechargeCommission
                        : 0
                    }}元
                  </span>
                </div>
              </el-card>
              <el-card class="topDistance">
                <div class="commissionItem">
                  <span>商品提成</span>
                  <span>
                    {{
                      pageData.goodsCommission ? pageData.goodsCommission : 0
                    }}元
                  </span>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :span="12">
        <div>
          <h3>服务中</h3>
          <div class="service">
            <el-card>
              <div class="serviceCtn" @click="$router.push('/home/inService')">
                <div>
                  <p>预计提成</p>
                  <p>
                    {{
                      pageData.expectedCommission
                        ? pageData.expectedCommission
                        : 0
                    }}元
                  </p>
                </div>
                <div>
                  <p>衣物件数</p>
                  <p>
                    {{ pageData.clothesCount ? pageData.clothesCount : 0 }}件
                  </p>
                </div>
              </div>
            </el-card>
            <p style="text-align: center; color: #827f7f;">
              <i style="font-size: 20px; color: #eb272f;">*</i>
              注: 客户取衣后, 即定义为服务完成, 即可得到相应提成。
            </p>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="tableBox" v-loading="loading">
      <h3>已完成信息</h3>
      <el-table :data="tableData" style="width: 100%;">
        <el-table-column label="序号" type="index" width="50px" align="center">
          <template slot-scope="scope">
            <span>{{
              (Number(formData.current) - 1) * 10 + scope.$index + 1
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="serialNumber"
          show-overflow-tooltip
          label="流水号"
          width="180"
          align="center"
        ></el-table-column>

        <el-table-column prop="userName" label="顾客姓名"></el-table-column>
        <el-table-column prop="phone" label="手机号" width="120" align="center">
          <template slot-scope="scope">
            <span v-hidephone> {{ scope.row.phone }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="commissionType"
          label="类型"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="projectName"
          label="名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="actuallyPaidPrice"
          label="金额(元)"
          align="center"
        ></el-table-column>
        <el-table-column prop="commissionPrice" label="提成(元)" align="center">
          <template slot-scope="scope">
            <span
              v-if="scope.row.decimalType == 1"
              style="color: #7ec82f; font-weight: 700;"
            >
              {{ scope.row.commissionPrice }}
            </span>
            <span v-else style="color: #f92672; font-weight: 700;">
              -{{ scope.row.commissionPrice }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="commissionStatus"
          label="状态"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="orderTime"
          label="状态时间"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="storeName"
          label="消费门店"
          align="center"
        ></el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              :disabled="!scope.row.currentStore"
              @click="gotoOrder(scope.row)"
            >
              关联订单
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="formData.current"
          :page-size="formData.size"
          layout="total, prev, pager, next"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { storeName, commissionDel } from "@/api/commission";
export default {
  name: "MyCommission",
  data() {
    return {
      formData: {
        beginTime: "",
        endTime: "",
        type: "",
        clothesNum: "",
        status: "",
        storeId: "",
        size: 10,
        current: 1,
      },
      total: 0,
      dates: null,
      loading: false,
      pickerOptions: {
        shortcuts: [
          {
            text: "昨天",
            onClick(picker) {
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", [start, start]);
            },
          },
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate.getTime();
          if (maxDate) {
            this.selectDate = "";
          }
        },
        disabledDate: (time) => {
          if (this.selectDate !== "") {
            const one = 90 * 24 * 3600 * 1000;
            const minTime = this.selectDate - one;
            const maxTime = this.selectDate + one;
            return (
              time.getTime() < minTime ||
              time.getTime() > maxTime ||
              time.getTime() > Date.now() - 8.64e6
            );
          }
        },
      },
      tableData: [],
      statusList: [
        { text: "已完成", value: 1 },
        { text: "已退款", value: 2 },
      ],
      typeList: [
        { text: "收衣", value: 1 },
        { text: "办卡", value: 2 },
        { text: "充值", value: 3 },
        { text: "商品", value: 4 },
      ],
      storeList: [],
      pageData: {},
    };
  },
  created() {
    this.getStoreName();
    this.pageInfo();
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    selectTimes() {
      this.formData.beginTime = this.dates ? this.dates[0] : "";
      this.formData.endTime = this.dates ? this.dates[1] : "";
    },
    reSet() {
      this.$refs.ruleForm.resetFields();
      this.dates = null;
      this.selectTimes();
      this.pageInfo();
    },
    queryInfo() {
      this.formData.current = 1;
      this.pageInfo();
    },
    async pageInfo() {
      this.loading = true;
      try {
        const { data: res } = await commissionDel(this.formData);
        console.log(res);
        if (res.code != 0) {
          this.$message.error(res.msg);
          this.loading = false;
          return;
        }
        this.tableData = res.data.records;
        this.pageData = res.data;
        this.total = res.data.total;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async getStoreName() {
      const { data: res } = await storeName();
      console.log("门店门店", res);
      this.storeList = res;
    },
    handleCurrentChange(val) {
      this.formData.current = val;
      this.pageInfo();
    },
    gotoOrder(data) {
      console.log(data);
      if (data.commissionType == "洗衣提成") {
        //洗衣提成
        this.$router.push({
          path: "/home/order",
          query: {
            orderNumber: data.serialNumber,
          },
        });
      } else if (data.commissionType == "办卡提成") {
        //办卡提成
        this.$router.push("/home/more/applyCardList");
      } else if (data.commissionType == "充值提成") {
        //充值 提成
        this.$router.push("/home/more/applyCardList");
      } else {
        // 商品订单
        this.$router.push("/home/more/commodityOrder");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.myCommission {
  padding: 20px;
  overflow: auto;
  .formBox {
    margin-top: 30px;
  }
  .demo-ruleForm {
    display: flex;
    flex-wrap: wrap;
  }
  .completeOrservice {
    > div:nth-child(1) {
      border-right: 1px dashed #ccc;
    }
    h3 {
      margin-left: 15px;
      font-size: 20px;
    }
    .completeCtn {
      padding: 0 20px;
      .topDistance {
        margin-top: 15px;
      }
    }
    .totalCommission {
      padding: 27px 0;
      text-align: center;
      font-size: 22px;
      height: 297px;
    }
    .commissionItem {
      display: flex;
      justify-content: space-between;
    }
    .service {
      padding: 0 20px;
      .serviceCtn {
        display: flex;
        justify-content: space-around;
        font-size: 20px;
        cursor: pointer;
        p {
          text-align: center;
        }
      }
    }
  }
  .footer {
    margin-top: 15px;
    text-align: right;
  }
}
</style>
